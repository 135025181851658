import React from 'react';
import {
  BrowserRouter,
  Outlet,
} from 'react-router-dom';
import AppRouter from './routes';
import { WhatsApp } from '@mui/icons-material';

function App() {
  return (
    <BrowserRouter>
      <div className="app-main-container">
        <AppRouter />
        <Outlet />
        <a href="https://wa.me/+919392005252" rel="noreferrer" target='_blank' className="whatsapp-icon-section">
          <WhatsApp />
        </a>
      </div>
    </BrowserRouter>
  );
}

export default App;
