import React, { useContext, useState } from 'react';
import BookingContext from '../../context/booking/context';
import foodImage from '../../assets/food.jpg';
import { CheckCircle, Circle } from '@mui/icons-material';

const FoodList = () => {
    // const [isLoading, setIsLoading] = useState(false);
    // const [list, setList] = useState([]);

    const [isSelected, setIsSelected] = useState(false);
    const { setOptInForFood } = useContext(BookingContext);
    // const { food } = addOns;

    // const onActionClick = (item) => {
    //     const foodIndex = food?.findIndex((cake) => item._id === cake._id);
    //     if (foodIndex > -1) {
    //         const udpatedAddOns = { ...addOns };
    //         const updatedFood = [...food]
    //         updatedFood?.splice(foodIndex, 1);
    //         udpatedAddOns.food = [...updatedFood];
    //         setAddOnDetails(udpatedAddOns);
    //     } else {
    //         const udpatedAddOns = { ...addOns };
    //         const updatedFood = [...food]
    //         updatedFood?.push(item);
    //         udpatedAddOns.food = [...updatedFood];
    //         setAddOnDetails(udpatedAddOns);
    //     }
    // };

    // const fetchListData = async () => {
    //     setIsLoading(true);
    //     const listReq = await fetch(`${API_BASE_URL}/food/get`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     });
    //     const resData = await listReq.json();
    //     const { data } = resData;
    //     setList(data);
    //     setIsLoading(false);
    // };

    // useEffect(() => {
    //     fetchListData();
    // }, []);

    // const fomatter = new Intl.NumberFormat();

    return (
        <div className="category-list-container" >
            <div className={`food-item-card-container ${isSelected ? ' selected' : ''}`} onClick={() => {
                setIsSelected(!isSelected);
                setOptInForFood(!isSelected);
            }}>
                <div className="image-section">
                    <img src={foodImage} alt="Food" />
                </div>
                <div className="text">
                    Opt in for food
                    <div className="desc">
                        We will arrange the food you wanted to be at the theatre
                    </div>
                </div>
                <div className={`selected-item ${isSelected ? 'selected' : ''}`}>
                    {isSelected ? <CheckCircle /> : <Circle />}
                </div>
            </div>
            {/* {!isLoading && list?.length > 0 && (
                <div className="heading">
                    Food Items
                </div>)}
            {isLoading && (
                <Loader description="Fetching data..." />
            )}
            <div className="list-flex-container">
                {!isLoading && list?.map((item) => {
                    const isAddedToList = food?.findIndex((cake) => item._id === cake._id) > -1;

                    return (
                        <div className={`theatre-card-container ${isAddedToList ? 'active-item' : ''}`}>
                            <div className="price">
                                <div className="left">
                                    <CurrencyRupeeOutlined />
                                    {fomatter?.format(item?.price)}
                                </div>
                            </div>
                            <div className="image-section">
                                <img src={item?.imageUrl} alt={item?.name} />
                            </div>
                            <div className="info-section">
                                <div className="name">
                                    {item?.name}
                                </div>
                                <div className="desc">
                                    {item?.description}
                                </div>
                                <div className="add-item-section">
                                    <Button size="small" onClick={() => onActionClick(item)} variant="outlined" color={!isAddedToList ? 'primary' : 'error'}>
                                        {isAddedToList ? 'Remove' : 'Add'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div> */}
            <div className='note-info'>
                <p>Note: Outside food is strictly not allowed inside the theatre room.</p>
            </div>
        </div >
    )
}

export default FoodList;
