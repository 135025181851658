import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

const Contact = () => {
    const navigate = useNavigate();

    const onBookNowClick = () => {
        navigate('/booknow')
    }

    return (
        <div className="contact-section app-container-wrapper">
            <div className="heading">
                We're now available at Boduppal
            </div>
            <div className="map-container">
                <iframe title="Binge Delight Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5674542379775!2d78.59548897345564!3d17.432533701506383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9e9947676f91%3A0xfccc49f1bead4495!2sBinge%20Delight!5e0!3m2!1sen!2sin!4v1699800495269!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            {/* <div className="sub-heading">
                Coming to your nearest location soon!
            </div> */}
            <div className="cta-section">
                <Button className='app-button' variant='contained' onClick={onBookNowClick}>
                    Book Now
                </Button>
            </div>
        </div>
    )
}

export default Contact;