/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BookingContext from './context';

const BookingProvider = ({ children }) => {
    const [theatre, setTheatreDetails] = useState({
        name: '',
        price: 0,
    });
    const [slot, setSlotDetails] = useState({});
    const [addOns, setAddOnDetails] = useState({
        categories: [],
        cakes: [],
        food: [],
        decorations: [],
        photography: []
    });
    const [totalPrice, setTotalPrice] = useState(0);
    const [noOfPersons, setNoOfPersons] = useState(4);
    const [extraPersons, setExtraPersons] = useState(0);
    const [costPerPerson, setCostPerPerson] = useState(299);
    const [optInForFood, setOptInForFood] = useState(false);
    const [gst, setGst] = useState(0);

    const onSetAddOnDetails = (data) => {
        setAddOnDetails(data)
    };

    const removeAddOn = () => { }

    const resetDetails = () => {
        setTheatreDetails({});
        setSlotDetails({});
        setAddOnDetails({
            categories: [],
            cakes: [],
            food: [],
            decorations: [],
            photography: []
        });
        setTotalPrice(0);
        setGst(0);
    }

    const updateTotalPrice = () => {
        let price = theatre?.price;
        const allItems = Object.values(addOns)?.filter((item) => item.length > 0);
        const addOnsList = [].concat(...allItems);
        price = addOnsList?.reduce((acc, o) => acc + o.price, price);
        if (extraPersons >= 1) {
            price = price + (extraPersons * costPerPerson);
        }
        setTotalPrice(price);
        // setGst((price * 18) / 100);
    }

    useEffect(() => {
        updateTotalPrice();
    }, [extraPersons, noOfPersons]);

    useEffect(() => {
        updateTotalPrice();
        setNoOfPersons(theatre?.noOfPersons);
        setCostPerPerson(theatre?.costPerPerson);
    }, [addOns, theatre, theatre?.price,])

    return (
        <BookingContext.Provider
            value={{
                theatre,
                slot,
                addOns,
                totalPrice,
                gst,
                noOfPersons,
                costPerPerson,
                extraPersons,
                optInForFood,
                setOptInForFood,
                setTheatreDetails: (data) => {
                    setTheatreDetails({ ...data })
                    updateTotalPrice();
                },
                setExtraPersons,
                setAddOnDetails: onSetAddOnDetails,
                removeAddOn,
                setSlotDetails,
                resetDetails,
            }}
        >
            {children}
        </BookingContext.Provider>
    )
}

export default BookingProvider;