import React from 'react';

const TermsConditionsPage = () => {
    return (
        <div className="static-pages-container">
            <div className="app-container-wrapper">
                <div className="heading">
                    BINGE DELIGHT THE PARTY LOUNGE <br/><br/>
                    Terms and Conditions 
                </div>
                <div className="content">
                    <ul>
                        <li>
                            Smoking and alcohol consumption are strictly prohibited in the premises of the lounge
                        </li>
                        <li>
                            Pets are not allowed in the premises of the Party lounge
                        </li>
                        <li>
                            If the customer damages something in the premises of the lounge, he must bear the corresponding costs.
                        </li>
                        <li>
                            As we continue to strive to improve our services, Binge Delight may reach out to guests to get feedback on their experience through calls or messages
                        </li>
                        <li>
                            As part of safety measure, We will not encourage sparkle as such other fire causing components inside theatre premises
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TermsConditionsPage;
