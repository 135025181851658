import React from 'react';
import { CallOutlined, Facebook, Instagram, LocationCityOutlined, MailOutlineRounded, YouTube } from '@mui/icons-material';
import './footer.scss';
import { Link } from 'react-router-dom';

const Footer = ({ secondaryFooter = false }) => {
    return (
        <footer className={`app-foote-container ${secondaryFooter ? 'secondary-footer' : ''}`}>
            <div className="top-section">
                <div className="app-container-wrapper">
                    <div className="left-section">
                        <div className="app-logo-section">
                            <span>Binge</span>Delight
                        </div>
                        <div className="social-info-section">
                            <div className="social-info">
                                <div className="info">
                                    <a href="https://instagram.com/binge_delight?igshid=MzRlODBiNWFlZA==">
                                        <Instagram />
                                    </a>
                                </div>
                            </div>
                            <div className="social-info">
                                <div className="info">
                                    <a href="https://www.facebook.com/profile.php?id=61551905585164">
                                        <Facebook />
                                    </a>
                                </div>
                            </div>
                            <div className="social-info">
                                <div className="info">
                                    <a href="https://youtube.com/@BingeDelight?si=Np4jl-xHOcoLb-GY">
                                        <YouTube />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-section">
                        <div className="app-links-section">
                            <div className="heading">
                                Userful Links
                            </div>
                            <ul>
                                <li>
                                    <Link to="/about-us">About us</Link>
                                </li>
                                <li>
                                    <Link to="/terms-conditions">Terms & Conditions</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="app-links-section">
                            <div className="heading">
                                Userful Links
                            </div>
                            <ul>
                                <li>
                                    <Link to="/refund-policy">Refund Policy</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="contact-info-section">
                            <div className="heading">
                                Contact
                            </div>
                            <div className="social-info">
                                <MailOutlineRounded />
                                <div className="info">
                                    <a href="mailto:admin@bingedelight.com">
                                        admin@bingedelight.com
                                    </a>
                                </div>
                            </div>
                            <div className="social-info">
                                <CallOutlined />
                                <div className="info">
                                    <a href="callto:+919392005252">
                                        +91 9392005252
                                    </a>
                                </div>
                            </div>
                            <div className="social-info">
                                <LocationCityOutlined />
                                <div className="info">
                                    2-76-9/2271,<br />
                                    Telephone Colony,<br />
                                    Medipally Mandal,<br />
                                    Boduppal, Medchal Dist - 500092
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-section">
                <div className="app-container-wrapper">
                    <div className="developed-by">
                        Designed & Developed by <a href="https://santhoshkorimi.com/" target="_blank" rel="noreferrer">Santhosh Korimi</a>
                        {/* This website is managed by <b>Binge Delight</b> */}
                    </div>
                    <div className="copy-right-text">
                        &copy;{new Date()?.getFullYear()} All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
