/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Alarm, CalendarToday, CurrencyRupeeOutlined } from '@mui/icons-material';
import Loader from '../components/loader';
import { API_BASE_URL } from '../constants';
import completedIcon from '../assets/check.png'

const OrderDetailsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const { orderId } = useParams();

    const fetchOrderDetails = async () => {
        setIsLoading(true);
        const listReq = await fetch(`${API_BASE_URL}/bookings/${orderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const resData = await listReq.json();
        const { data } = resData;
        setOrderDetails(data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchOrderDetails();
        document?.body?.setAttribute('style', '');
    }, [])

    const fomatter = new Intl.NumberFormat();
    const { userDetails, theatre, slot, razorpayDetails } = orderDetails || { theatre: {}, userDetails: {}, slot: { date: '', time: '' }, razorpayDetails: { amount: '' } }

    return (
        <div className="order-details-page-container">
            {isLoading && (
                <Loader />
            )}
            {!isLoading && (
                <div className='app-container-wrapper'>
                    <div className="order-info-success-container">
                        <div className="image-section">
                            <img src={completedIcon} alt="Completed" />
                        </div>
                        <div className="oi-info-section">
                            <div className="heading">
                                Order Confirmed
                            </div>
                            <div className="desc">
                                Your slot booking is confirmed enjoy your special moments in <b>Binge Delight</b>
                            </div>
                        </div>
                    </div>
                    <div className="theater-details-section">
                        <div className="right-section">
                            <div className="order-id">
                                Booking ID: {orderId}
                            </div>
                            <div className="name">
                                {theatre?.name}
                            </div>
                            <div className="price">
                                <CurrencyRupeeOutlined />
                                {fomatter?.format(orderDetails?.orderAmount)}
                            </div>
                            <div className="info-section">
                                <CalendarToday />{slot?.date || ''}
                            </div>
                            <div className="info-section">
                                <Alarm /> {slot?.time || ''}
                            </div>
                            <div className="info-section">
                                Name: {userDetails?.name}
                            </div>
                            <div className="info-section">
                                Phone Number: {userDetails?.mobileNumber}
                            </div>
                            <div className="info-section">
                                Advance Amount Paid: Rs.{razorpayDetails?.amount && (razorpayDetails.amount / 100).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrderDetailsPage;
