import React from 'react';
import {
  HomePage,
  BooknowPage,
  TheatreDetailsPage,
  RefundPolicy,
  PrivacyPolicy,
  TermsAndConditions,
  AboutUs,
  OrderDetailsPage,
  PaymentStatusPage,
} from './pages';
import {
  Route,
  Routes,
} from 'react-router';
import BookingProvider from './context/booking/provider';
import PageWrapperComponent from './components/page-wrapper';
import ScrollToTop from './components/scroll-to-top';

const NotFound = () => {
  <div className="static-pages-container">
    <div className="app-container-wrapper">
      <div className="heading">
        404 | Not found any matched route.
      </div>
    </div>
  </div>
};
/*Routes is used to be Switch*/
const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={
          <PageWrapperComponent>
            <HomePage />
          </PageWrapperComponent>
        } />
        <Route path="/booknow" element={
          <PageWrapperComponent showBookNow={false}>
            <BooknowPage />
          </PageWrapperComponent>
        } />
        <Route path="/about-us" element={
          <PageWrapperComponent>
            <AboutUs />
          </PageWrapperComponent>
        } />
        <Route path="/terms-conditions" element={
          <PageWrapperComponent>
            <TermsAndConditions />
          </PageWrapperComponent>
        } />
        <Route path="/privacy-policy" element={
          <PageWrapperComponent>
            <PrivacyPolicy />
          </PageWrapperComponent>
        } />
        <Route path="/refund-policy" element={
          <PageWrapperComponent>
            <RefundPolicy />
          </PageWrapperComponent>
        } />
        <Route path="/bookings/order/:orderId" element={
          <PageWrapperComponent>
            <OrderDetailsPage />
          </PageWrapperComponent>
        } />
        <Route path="/booknow/:theatreId" element={
          <PageWrapperComponent showBookNow={false} secondaryHeader>
            <BookingProvider>
              <TheatreDetailsPage />
            </BookingProvider>
          </PageWrapperComponent>
        } />
        <Route path="/payment/validate/:txnId" element={
          <PageWrapperComponent showBookNow={false} secondaryHeader>
            <PaymentStatusPage />
          </PageWrapperComponent>
        } />
        <Route path="*" element={
          <PageWrapperComponent>
            <div className="static-pages-container">
              <div className="app-container-wrapper">
                <div className="heading">
                  404 | Not found any matched route.
                </div>
              </div>
            </div>
          </PageWrapperComponent>
        } />
      </Routes>
      <ScrollToTop />
    </>
  );
};
export default AppRouter;
