import React, { useEffect, useState } from 'react';
import { Box, Button, Fade, Modal } from '@mui/material';
import { useNavigate } from 'react-router';
import { API_BASE_URL } from '../../constants';
import { LoadingButton } from '@mui/lab';
import { getCookie, setCookie } from '../../utils/cookie';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '1080px',
  bgcolor: 'background.paper',
  p: 4,
};

const LandingSection = () => {
  const [promotionalData, setPromotionalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPromotionalModal, setShowPromotionalModal] = useState(false);

  const fetchTheatresList = async () => {
    setIsLoading(true);
    const req = await fetch(`${API_BASE_URL}/banners/get`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const response = await req.json();
    setPromotionalData(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchTheatresList();
  }, []);

  useEffect(() => {
    const cookie = getCookie('showPromotional');
    if (promotionalData.length && cookie !== 'true') {
      setShowPromotionalModal(true);
      setCookie('showPromotional', 'true', 0.125);
    }
  }, [promotionalData]);

  const navigate = useNavigate();
  const onBookNowClick = () => {
    navigate('/booknow')
  };

  return (
    <>
      <div className="flex-container">
        <div className="left-side">
          <div className="heading">
            One stop destination for all celebrations
          </div>
          <div className="sub-heading">
            Enjoy your special moments in our Luxurious private theatres!
          </div>
          <div className="desc">
            BingeDelight welcomes you for an extraordinary private theatre experience with personalised decor. Our exclusive premium private theatres feature enhanced 4K resolution with DOLBY atmos sound system.
          </div>
          <div className="cta-section">
            <Button className='app-button' variant='contained' onClick={onBookNowClick}>
              Book Now
            </Button>
          </div>
        </div>
      </div>
      {showPromotionalModal && (
        <Modal
          open={showPromotionalModal}
          onClose={() => {
            setShowPromotionalModal(false);
          }}
          aria-labelledby="Promotional Information"
          aria-describedby=""
        >
          <Fade in={showPromotionalModal}>
            <Box className="banner-info-modal-container" sx={{
              ...style,
              width: 1080
            }}>
              <div className="modal-close-button" onClick={() => {
                setShowPromotionalModal(false);
              }}>
                <Close />
              </div>
              <div className="heading">
                {promotionalData?.[0]?.name}
              </div>
              <div className="image-section">
                <img src=
                  {promotionalData?.[0]?.imageUrl} alt="Promotional"
                />
              </div>
              <div className="modal-buttons-container">
                <LoadingButton
                  variant="outlined"
                  className="close-button"
                  onClick={() => {
                    setShowPromotionalModal(false);
                  }}>
                  Close
                </LoadingButton>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </>
  )
}

export default LandingSection;