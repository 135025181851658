import React, { useEffect, useState } from 'react';
import TheatreCard from './theatre-card';
import Loader from '../loader';
import { API_BASE_URL } from '../../constants';

const TheatresList = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTheatresList = async () => {
        setIsLoading(true);
        const req = await fetch(`${API_BASE_URL}/theatres/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const response = await req.json();
        setList(response.data);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchTheatresList();
    }, []);

    return (
        <div className="theatres-list-container">
            {isLoading && (
                <Loader description='Fetching theatres...' />
            )}
            {!isLoading && list?.map((item) => (
                <TheatreCard data={item} />
            ))}
        </div>
    )
}

export default TheatresList;
