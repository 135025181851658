import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CurrencyRupeeOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { API_BASE_URL } from '../../constants';
import Loader from '../../components/loader';
import SlotForm from './form';
import EventCategoryList from '../../components/details/category-list';
import FoodList from '../../components/details/food-list';
import DecorationsList from '../../components/details/decorations-list';
import PhotographyList from '../../components/details/photography-list';
import CakesList from '../../components/details/cakes-list';
import Summary from './summary';
import BookingContext from '../../context/booking/context';
import dayjs from 'dayjs';
import { Box, Button, MobileStepper } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import './theatre.scss';
import GiftList from '../../components/details/gift-list';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const TheatreDetailsPage = () => {
    const params = useParams();
    const { theatreId } = params;
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [details, setDetails] = useState({});
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = details?.carouselImages?.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const parts = theatreId?.split('-');
    const id = parts?.[parts?.length - 1];

    const { resetDetails, setTheatreDetails, setSlotDetails } = useContext(BookingContext);

    const fetchTheatreDetails = async () => {
        setIsLoading(true);
        const req = await fetch(`${API_BASE_URL}/theatres/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const response = await req.json();
        setDetails(response.data);
        setIsLoading(false);
        setTheatreDetails({
            name: response?.data?.name,
            price: response?.data?.price,
            noOfPersons: response?.data?.noOfPersons,
            costPerPerson: response?.data?.costPerPerson
        });
        setSlotDetails({
            date: dayjs(new Date()).format('MM/DD/YYYY'),
            slot: '',
        })
    };

    useEffect(() => {
        fetchTheatreDetails();
        setIsMobile(window.innerWidth <= 768);
        return () => {
            resetDetails();
        }
    }, [])

    const fomatter = new Intl.NumberFormat();

    return (
        <div className="theatre-details-page-container">
            {isLoading && (
                <Loader description='Fetching details...' />
            )}
            {!isLoading && (
                <div className="flex-container">
                    <div className="left-side">
                        <div className="theater-details-section">
                            <Box sx={{ flexGrow: 1 }}>
                                <AutoPlaySwipeableViews
                                    axis={'x'}
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {details?.carouselImages?.map((step, index) => (
                                        <div key={step}>
                                            {Math.abs(activeStep - index) <= 2 ? (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        maxHeight: 550,
                                                        maxWidth: '80%',
                                                        display: 'block',
                                                        overflow: 'hidden',
                                                        objectFit: 'contain',
                                                        borderRadius: '10px',
                                                        margin: 'auto',
                                                    }}
                                                    src={step}
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </AutoPlaySwipeableViews>
                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeStep}
                                    nextButton={
                                        <Button
                                            size="small"
                                            onClick={handleNext}
                                            disabled={activeStep === maxSteps - 1}
                                        >
                                            Next
                                            <KeyboardArrowRight />
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>

                                            <KeyboardArrowLeft />
                                            Back
                                        </Button>
                                    }
                                />
                            </Box>
                            <div className="right-section">
                                <div className="name">
                                    {details?.name}
                                </div>
                                <div className="description" dangerouslySetInnerHTML={{ __html: details?.description?.replace('\\n', '<br />') }} />
                                <div className="price">
                                    <CurrencyRupeeOutlined />
                                    {fomatter?.format(details?.price)}
                                    <div style={{ marginLeft: 8 }}>
                                        (up to {details?.noOfPersons} persons)
                                    </div>
                                </div>
                                <SlotForm maxNoOfPersons={details?.maxNoOfPersons} noOfPersons={details?.noOfPersons} theatreId={id} slotsList={details?.slots} />
                                <div className="allowed-persons-info">
                                    Cost for including 1 extra person:
                                    <div className="price">
                                        <CurrencyRupeeOutlined />
                                        {fomatter?.format(details?.costPerPerson)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <EventCategoryList />
                        <CakesList />
                        <DecorationsList />
                        <PhotographyList />
                        <GiftList />
                        <FoodList />
                    </div>
                    {!isMobile && (
                        <div className="right-side">
                            <div className="content">
                                <div className="heading">
                                    Booking Summary
                                </div>
                                <Summary theatreId={id} />
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <div className="booking-summary-xs-container">
                            <Summary isMobile={isMobile} theatreId={id} />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default TheatreDetailsPage;
