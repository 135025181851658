import React from 'react';
import decor from '../../assets/decor.png';
import floral from '../../assets/bouquet.png';
import photography from '../../assets/photography.png';
import screening from '../../assets/screening.png';
import food from '../../assets/food.png';
import cake from '../../assets/cake.png';

const servicesList = [
    {
        name: 'Decoration',
        image: decor,
    },
    {
        name: 'Food & Beverages',
        image: food,
    },
    {
        name: 'Cakes',
        image: cake,
    },
    {
        name: 'Roses & Bouquet',
        image: floral,
    },
    {
        name: 'Screening',
        image: screening,
    },
    {
        name: 'Photography',
        image: photography,
    },
];

const Services = () => {
    return (
        <div className="services-list-container">
            <div className="content app-container-wrapper">
                <div className="left-side">
                    <div className="heading">
                        What we offer
                    </div>
                    <div className="desc">
                        We aim to provide everything you need to enjoy your special moments. Our comprehensive offerings include
                    </div>
                </div>
                <div className="right-side">
                    <div className="info-flex-container">
                        {servicesList?.map((item) => (
                            <div className="service-card">
                                <div className="icon">
                                    <img src={item.image} alt={item.name} />
                                </div>
                                <div className="name">
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;
