/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import BookingContext from '../../context/booking/context';
import { HourglassBottom } from '@mui/icons-material';
import { API_BASE_URL } from '../../constants';
import { getMinutes, getMinutesNow } from '../../utils/common';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const personsList = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const SlotForm = ({ slotsList, theatreId, noOfPersons, maxNoOfPersons }) => {
    const [date, setDate] = useState(new Date());
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [slots, setSlots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [slotIndex, setSlotIndex] = useState(0);
    const [persons, setNoOfPersons] = useState(4);

    const { setSlotDetails, setExtraPersons } = useContext(BookingContext);

    const onSlotChange = (e) => {
        const {
            target: { value },
        } = e;
        setSelectedSlot(value);
    };

    const onPersonsChange = (e) => {
        const {
            target: { value },
        } = e;
        setNoOfPersons(value);
        setExtraPersons(value - noOfPersons);
    };

    const onDateChange = (val) => {
        setDate(dayjs(val).format('MM/DD/YYYY'));
        setSlotDetails({
            date: dayjs(val).format('MM/DD/YYYY'),
            slot: '',
        });
        setSelectedSlot('');
    }

    const fetchSlotsAvailability = async (index) => {
        const updatedSlots = [...slots];
        const slot = slotsList?.[index];
        if (slot) {
            const request = await fetch(`${API_BASE_URL}/bookings/check-availability`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    theatreId,
                    date: dayjs(date).format('MM/DD/YYYY'),
                    time: slot?.timeslot,
                })
            });
            const data = await request.json();

            const slotStartTime = slot?.timeslot?.split('-')?.[0];
            const now = getMinutesNow();
            const isPm = slotStartTime.includes('pm');
            const start = getMinutes(slotStartTime.replaceAll('am', '').replaceAll('pm', ''), isPm);
            const isCurrentDay = new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
            updatedSlots[index] = ({
                ...slot,
                isAvailable: isCurrentDay ? start > now && data.success : data.success
            });
            if (index < slotsList.length) {
                setSlotIndex(index + 1);
            }

            if (index + 1 === slotsList.length) {
                setIsLoading(false);
            }
            setSlots(updatedSlots);
        }
    }

    useEffect(() => {
        if (date && selectedSlot) {
            setSlotDetails({
                date: dayjs(date).format('MM/DD/YYYY'),
                slot: selectedSlot,
            })
        }
    }, [date, selectedSlot]);

    useEffect(() => {
        fetchSlotsAvailability(slotIndex);
    }, [slotIndex])

    useEffect(() => {
        setSelectedSlot(null);
        setSlotIndex(0);
        setIsLoading(true)
    }, [date])

    const personIndex = personsList?.indexOf(maxNoOfPersons)
    let finalPersonsList = personsList?.slice(0, personIndex + 1);
    const availableSlots = slots?.filter((item) => item.isAvailable === true).length;
    let color;
    if (availableSlots === 0) {
        color = 'red';
    } else {
        color = 'green';
    }

    return (
        <div className="form-container">
            <div className="slot-form-container">
                <div className="date-section">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker minDate={dayjs(new Date())} onChange={(val) => onDateChange(val)} className='create-form-input' label="Select Date" value={dayjs(date)} />
                    </LocalizationProvider>
                </div>
                <div className="slot-section">
                    {isLoading && (
                        <div className='loader-section'>
                            <HourglassBottom /> Fetching Availability...
                        </div>
                    )}
                    {!isLoading && (
                        <FormControl className='create-form-input'>
                            <InputLabel id="demo-multiple-checkbox-label">Slot</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                value={selectedSlot}
                                onChange={onSlotChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => {
                                    return selected
                                }}
                                MenuProps={MenuProps}
                            >
                                {slots?.map((item) => (
                                    <MenuItem disabled={!item.isAvailable} key={item?.timeslot} value={item?.timeslot}>
                                        {item?.timeslot}
                                    </MenuItem>
                                ))}
                            </Select>
                            <div className="slots-available-info">
                                <div className="slot">
                                    <p style={{ color }}>
                                        {availableSlots === 0 ? '0 slots are available' : (availableSlots === 1 ? '1 slot is available' : `${availableSlots} slots are available`)}
                                    </p>
                                </div>
                            </div>
                        </FormControl>
                    )}
                </div>
                <div className="persons-section">
                    <FormControl className='create-form-input'>
                        <InputLabel id="demo-multiple-checkbox-label">No of persons</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            value={persons}
                            onChange={onPersonsChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => {
                                return selected
                            }}
                            MenuProps={MenuProps}
                        >
                            {finalPersonsList?.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default SlotForm;
