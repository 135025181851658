import React from 'react';

const BookingContext = React.createContext({
    theatre: {},
    slot: {},
    addOns: {
        categories: [],
        cakes: [],
        food: [],
        decorations: [],
        photography: [],
    },
    totalPrice: 0,
    gst: 0,
    noOfPersons: 4,
    extraPersons: 0,
    costPerPerson: 299,
    optInForFood: false,
    setOptInForFood: () => { },
    removeAddOn: () => { },
    setTheatreDetails: () => { },
    setAddOnDetails: () => { },
    setSlotDetails: () => { },
});

export default BookingContext;
