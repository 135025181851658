import React from 'react';
import TheatresList from '../../components/booknow/theatres'
import './booknow.scss'

const BooknowPage = () => {
    return (
        <div className="book-now-page-container app-container-wrapper">
            <TheatresList />
        </div>
    )
}

export default BooknowPage;