import React from 'react';
import { useNavigate } from 'react-router';
import { CurrencyRupeeOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, MobileStepper } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const TheatreCard = ({ data }) => {
    const navigate = useNavigate();
    const { name, carouselImages, description, price, _id } = data;

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = carouselImages?.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const fomatter = new Intl.NumberFormat();

    const onCheckSlotsClick = () => {
        navigate(`/booknow/${name?.split(' ')?.join('_')}-${_id}`)
    };

    return (
        <div className="theatre-card-container">
            <div className="image-section">
                <Box sx={{ flexGrow: 1 }}>
                    <AutoPlaySwipeableViews
                        axis={'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {carouselImages?.map((step, index) => (
                            <div key={step} onClick={onCheckSlotsClick}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 350,
                                            display: 'block',
                                            overflow: 'hidden',
                                            width: '100%',
                                        }}
                                        src={step}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                Next
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>

                                <KeyboardArrowLeft />
                                Back
                            </Button>
                        }
                    />
                </Box>
            </div>
            <div className="info-section">
                <div className="content">
                    <div className="left">
                        <div className="name">
                            {name}
                        </div>
                        <div className="desc" dangerouslySetInnerHTML={{ __html: description?.replace('\\n', '<br />') }} />
                    </div>
                    <div className="right">
                        <Button className='app-secondary-button' onClick={onCheckSlotsClick}>
                            Check slots
                        </Button>
                    </div>
                </div>
                <div className="price">
                    <div className="left">
                        <CurrencyRupeeOutlined />
                        {fomatter?.format(price)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TheatreCard;
