import React, { useContext, useEffect, useState } from 'react';
import { CurrencyRupeeOutlined } from '@mui/icons-material';
import BookingContext from '../../context/booking/context';
import { API_BASE_URL } from '../../constants';
import { Button } from '@mui/material';
import Loader from '../../components/loader';

const GiftList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const { addOns, setAddOnDetails } = useContext(BookingContext);
    const { food } = addOns;

    const onActionClick = (item) => {
        const foodIndex = food?.findIndex((cake) => item._id === cake._id);
        if (foodIndex > -1) {
            const udpatedAddOns = { ...addOns };
            const updatedFood = [...food]
            updatedFood?.splice(foodIndex, 1);
            udpatedAddOns.food = [...updatedFood];
            setAddOnDetails(udpatedAddOns);
        } else {
            const udpatedAddOns = { ...addOns };
            const updatedFood = [...food]
            updatedFood?.push(item);
            udpatedAddOns.food = [...updatedFood];
            setAddOnDetails(udpatedAddOns);
        }
    };

    const fetchListData = async () => {
        setIsLoading(true);
        const listReq = await fetch(`${API_BASE_URL}/food/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const resData = await listReq.json();
        const { data } = resData;
        setList(data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchListData();
    }, []);

    const fomatter = new Intl.NumberFormat();

    return (
        <div className="category-list-container" >
            {!isLoading && list?.length > 0 && (
                <div className="heading">
                    Gifts
                </div>)}
            {isLoading && (
                <Loader description="Fetching data..." />
            )}
            <div className="list-flex-container">
                {!isLoading && list?.map((item) => {
                    const isAddedToList = food?.findIndex((cake) => item._id === cake._id) > -1;

                    return (
                        <div className={`theatre-card-container ${isAddedToList ? 'active-item' : ''}`}>
                            <div className="price">
                                <div className="left">
                                    <CurrencyRupeeOutlined />
                                    {fomatter?.format(item?.price)}
                                </div>
                            </div>
                            <div className="image-section">
                                <img src={item?.imageUrl} alt={item?.name} />
                            </div>
                            <div className="info-section">
                                <div className="name">
                                    {item?.name}
                                </div>
                                <div className="desc">
                                    {item?.description}
                                </div>
                                <div className="add-item-section">
                                    <Button size="small" onClick={() => onActionClick(item)} variant="outlined" color={!isAddedToList ? 'primary' : 'error'}>
                                        {isAddedToList ? 'Remove' : 'Add'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div >
    )
}

export default GiftList;
