import React from 'react';
import { LandingSection, EventCategories, Services, Contact } from '../../components/home';
import './home.scss';

const HomePage = () => {
  return (
    <div className="home-page-container">
      <LandingSection />
      <EventCategories />
      <Services />
      <Contact />
    </div>
  );
};

export default React.memo(HomePage);
{/* <a href="https://www.freepik.com/free-vector/happy-friends-home-party-isolated-flat-vector-illustration-cartoon-group-students-dancing-talking-having-fun-together-apartment_10172831.htm#query=private%20party%20illustration&position=9&from_view=search&track=ais">Image by pch.vector</a> on Freepik */}
// Photo by <a href="https://unsplash.com/@antenna?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Antenna</a> on <a href="https://unsplash.com/photos/ZDN-G1xBWHY?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  