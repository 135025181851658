import React, { useState, useEffect, useRef } from 'react';
import {
  useNavigate,
} from 'react-router';
import { Button } from '@mui/material';
import { CallOutlined, Close, Facebook, Instagram, MailOutlineRounded, Menu, RoomOutlined, YouTube } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import './layout.scss';

const AppNavbar = ({ secondaryHeader, showBookNow }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const menuRef = useRef(null);

  const onRouteClick = (route) => {
    setShowMenu(false);
    navigate(route);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={`app-top-navbar-container ${secondaryHeader ? 'secondary-header' : ''}`}>
      <div className="top-section">
        <div className="app-container-wrapper">
          <div className="left-side">
            <div className="social-info">
              <div className="info">
                <a href="https://instagram.com/binge_delight?igshid=MzRlODBiNWFlZA==">
                  <Instagram />
                </a>
              </div>
            </div>
            <div className="social-info">
              <div className="info">
                <a href="https://www.facebook.com/profile.php?id=61551905585164">
                  <Facebook />
                </a>
              </div>
            </div>
            <div className="social-info">
              <div className="info">
                <a href="https://youtube.com/@BingeDelight?si=Np4jl-xHOcoLb-GY">
                  <YouTube />
                </a>
              </div>
            </div>
          </div>
          <div className="right-side">
            {!showMenu && (
              <div className="menu-icon" onClick={() => setShowMenu(true)}>
                <Menu />
              </div>
            )}
            {showMenu && (
              <div className="menu-icon" onClick={() => setShowMenu(false)}>
                <Close />
              </div>
            )}
            <div className="social-info">
              <RoomOutlined />
              <div className="info">
                Boduppal, Hyderabad
              </div>
            </div>
            <div className="social-info">
              <MailOutlineRounded />
              <div className="info">
                <a href="mailto:admin@bingedelight.com">
                  admin@bingedelight.com
                </a>
              </div>
            </div>
            <div className="social-info">
              <CallOutlined />
              <div className="info">
                <a href="callto:+919392005252">
                  +91 9392005252
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="app-container-wrapper">
          <div className="app-logo-section">
            <Link to="/"><span>Binge</span>Delight</Link>
          </div>
          <div className="menu-links-section">
            {showBookNow && (
              <div className="book-now-button">
                <Button variant="contained" className='app-button' onClick={() => onRouteClick('/booknow')}>
                  Book now
                </Button>
              </div>
            )}
            <ul>
              <li onClick={() => onRouteClick('/')}>
                <Link>Home</Link>
              </li>
              <li onClick={() => onRouteClick('/about-us')}>
                <Link>About us</Link>
              </li>
              <li onClick={() => onRouteClick('/booknow')}>
                <Link>Theatres</Link>
              </li>
              <li onClick={() => onRouteClick('/refund-policy')}>
                <Link>Refund Policy</Link>
              </li>
              {showBookNow && (
                <li>
                  <Button variant="contained" className='app-button' onClick={() => onRouteClick('/booknow')}>
                    Book now
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {showMenu && (
        <ul className='menu-options-container-xs' ref={menuRef}>
          <li onClick={() => onRouteClick('/')}>
            <Link>Home</Link>
          </li>
          <li onClick={() => onRouteClick('/about-us')}>
            <Link>About us</Link>
          </li>
          <li onClick={() => onRouteClick('/booknow')}>
            <Link>Theatres</Link>
          </li>
          <li onClick={() => onRouteClick('/refund-policy')}>
            <Link>Refund Policy</Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default AppNavbar;
