import React, { useContext, useEffect, useState } from 'react';
import { CurrencyRupeeOutlined } from '@mui/icons-material';
import { API_BASE_URL } from '../../constants';
import Loader from '../../components/loader';
import { Button } from '@mui/material';
import BookingContext from '../../context/booking/context';

const CategoriesList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);

    const { addOns, setAddOnDetails } = useContext(BookingContext);
    const { categories } = addOns;

    const onActionClick = (item) => {
        const categoryIndex = categories?.findIndex((cake) => item._id === cake._id);
        if (categoryIndex > -1) {
            const udpatedAddOns = { ...addOns };
            const updatedCategories = [...categories]
            updatedCategories?.splice(categoryIndex, 1);
            udpatedAddOns.categories = [...updatedCategories];
            setAddOnDetails(udpatedAddOns);
        } else {
            const udpatedAddOns = { ...addOns };
            const updatedCategories = [item];
            udpatedAddOns.categories = [...updatedCategories];
            setAddOnDetails(udpatedAddOns);
        }
    };

    const fetchListData = async () => {
        setIsLoading(true);
        const listReq = await fetch(`${API_BASE_URL}/categories/get`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const resData = await listReq.json();
        const { data } = resData;
        setList(data);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchListData();
    }, []);

    const fomatter = new Intl.NumberFormat();

    return (
        <div className="category-list-container">
            {!isLoading && list?.length > 0 && (
                <div className="heading">
                    Event Decoration
                </div>)}
            {isLoading && (
                <Loader description="Fetching data..." />
            )}
            <div className="list-flex-container">
                {!isLoading && list?.map((item) => {
                    const isAddedToList = categories?.findIndex((cake) => item._id === cake._id) > -1;

                    return (
                        <div className={`theatre-card-container ${isAddedToList ? 'active-item' : ''}`}>
                            <div className="price">
                                <div className="left">
                                    <CurrencyRupeeOutlined />
                                    {fomatter?.format(item?.price)}
                                </div>
                            </div>
                            <div className="image-section">
                                <img src={item?.imageUrl} alt={item?.name} />
                            </div>
                            <div className="info-section">
                                <div className="name">
                                    {item?.name}
                                </div>
                                <div className="desc">
                                    {item?.description}
                                </div>
                                <div className="add-item-section">
                                    <Button size="small" onClick={() => onActionClick(item)} variant="outlined" color={!isAddedToList ? 'primary' : 'error'}>
                                        {isAddedToList ? 'Remove' : 'Add'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {list?.length > 0 && (
                <div className='note-info'>
                <p>Note: You can select additional add-on's in the next sections. Above event decorations are not customizable.</p>
            </div>)}
        </div>
    )
}

export default CategoriesList;
