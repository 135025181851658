import React, { useContext } from 'react';
import { CurrencyRupee, DeleteOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import BookingContext from '../../context/booking/context';

const SummaryItemCard = ({ data, type }) => {
    const fomatter = new Intl.NumberFormat();

    const { addOns, setAddOnDetails } = useContext(BookingContext);

    const onRemoveItem = (dataItem) => {
        const items = addOns?.[type];
        const udpatedAddOns = { ...addOns };
        const updatedItems = [...items];
        const itemIndex = items?.findIndex((el) => el._id === dataItem?._id);
        if (itemIndex > -1) {
            updatedItems?.splice(itemIndex, 1);
            udpatedAddOns[type] = [...updatedItems];
            setAddOnDetails(udpatedAddOns);
        }
    }

    return (
        <>
            {data?.length > 0 && (
                <div className="item-info-container">
                    <div className="heading">
                    {type === "food" ? "Gifts" 
                        : type === "categories" ? "Event Decoration" 
                        : type === "decorations" ? "Add On's" 
                        : type}
                    </div>
                    {data?.map((item) => (
                        <div className="add-on-item-info-container">
                            <div className="name">
                                {item.name}
                            </div>
                            <div className="price">
                                <CurrencyRupee />
                                {fomatter?.format(item.price)}
                                <IconButton size="small" color="error" onClick={() => onRemoveItem(item)}>
                                    <DeleteOutline />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default SummaryItemCard;
