export const getBase64String = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
        return reader.result;
    };
    reader.onerror = error => {
        console.log('Error: ', error);
    };
}

export const getPreSignedURL = (awsFileKey) => {
    return `https://bingedelight.s3.ap-south-1.amazonaws.com/${awsFileKey}`;
};

export function getMinutes(str, isPm) {
    var time = str.split(':');
    return time[0] * 60 + time[1] * 1 + (isPm ? 720 : 0);
}

export function getMinutesNow() {
    var timeNow = new Date();
    return timeNow.getHours() * 60 + timeNow.getMinutes();
}