import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import failureIcon from '../../assets/failure.png';
import { API_BASE_URL } from '../../constants';
import Loader from '../../components/loader';
import './status.scss';

const PaymentStatusPage = () => {
    const { txnId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [showPaymentFailed, setShowPaymentFailedInfo] = useState(false);

    const checkPaymentStatus = async () => {
        const req = await fetch(`${API_BASE_URL}/bookings/status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                merchantTransactionId: txnId
            })
        });
        const response = await req.json();
        if (response.success) {
            submitCreateOrderRequest(response?.data?.data);
        }
        setIsLoading(false)
    };

    const submitCreateOrderRequest = async (razorpayDetails) => {
        setIsLoading(true);
        const orderObject = localStorage.getItem('orderData');
        const parsedData = typeof orderObject === 'string' ? JSON.parse(orderObject) : orderObject;
        const paymentObject = {
            ...parsedData,
            razorpayDetails,
        }
        if (orderObject) {
            const request = await fetch(`${API_BASE_URL}/bookings/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(paymentObject)
            });
            const response = await request.json();
            if (response.success) {
                document?.body?.setAttribute('style', '');
                localStorage.removeItem('orderData')
                navigate(`/bookings/order/${response?.data?._id}`)
            } else {
                setIsLoading(false);
                setShowPaymentFailedInfo(true)
            }
        }
    }

    useEffect(() => {
        checkPaymentStatus();
    }, []);

    return (
        <div className='payment-status-page-container'>
            {isLoading && (
                <div className="loader-container">
                    <Loader description="We are verifying the payment status, Please wait..." />
                </div>
            )}
            {!isLoading && showPaymentFailed && (
                <div className='payment-failed-info'>
                    <div className="content">
                        <div className="icon-section">
                            <img src={failureIcon} alt="Failed" />
                        </div>
                        <div className="heading">
                            Payment Failed
                        </div>
                        <div className="desc">
                            Hey there, we tried to charge but something went wrong. Please update your payment method and try again.
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PaymentStatusPage;
