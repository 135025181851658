import React from 'react';
import AppNavbar from './layout';
import Footer from './footer';

const PageWrapperComponent = ({ children, secondaryHeader = false, showBookNow = true }) => {
    return (
        <>
            <AppNavbar secondaryHeader={secondaryHeader} showBookNow={showBookNow} />
            {children}
            <Footer secondaryFooter={secondaryHeader} />
        </>
    )
}

export default PageWrapperComponent;
