import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="static-pages-container">
            <div className="app-container-wrapper">
                {/* <div className="heading">
                    About Us
                </div> */}
                <div className="content">
                    <div className="info">BINGE DELIGHT THE PARTY LOUNGE offers you to experience extraordinary private theatre experience with personalised decor. Our exclusive premium private theatres feature enhanced 4K resolution with DOLBY atmos sound system.
                        You can celebrate your special occasions such as Birthday's, anniversaries, Bride to be, Kitty parties any what not many more!!!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
