import React from 'react';

const ListItemCard = ({ data }) => {
    return (
        <div className="list-item-card-container">
            <div className="image-section">
                <div className="overlay" />
                <img src={data.src} alt={data.name} />
            </div>
            <div className="item-name">
                {data.name}
            </div>
        </div>
    )
}

export default ListItemCard;
