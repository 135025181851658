import React, { useContext, useEffect, useState } from 'react';
import useRazorpay from "react-razorpay";
import { Alarm, CalendarToday, CurrencyRupee, ExpandLess, ExpandMore, Movie } from '@mui/icons-material';
import { Box, Button, Checkbox, Fade, FormControlLabel, Modal, TextField } from '@mui/material';
import SummaryItemCard from './summary-item-card';
import BookingContext from '../../context/booking/context';
import { API_BASE_URL } from '../../constants';
import loaderImage from '../../assets/process.gif'
import completedIcon from '../../assets/check.png'
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '1080px',
    bgcolor: 'background.paper',
    p: 4,
};

const Summary = ({ theatreId, isMobile }) => {
    const navigate = useNavigate();

    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [isOrderCreating, setIsOrderCreating] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [amount, setAmount] = useState(0)
    const [showDetails, setShowDetails] = useState(false)
    const [userDetails, setUserDetails] = useState({
        name: '',
        mobileNumber: '',
        specialPersonName: '',
    });
    const [agreeToConditions, setAgreeToConditions] = useState(false);
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);

    const { slot, theatre, addOns, totalPrice, extraPersons, costPerPerson, optInForFood } = useContext(BookingContext);
    const payload = {
        amount: 750,
    };
    const orderObject = {
        theatre: theatreId,
        slot: {
            date: slot?.date,
            time: slot?.slot,
        },
        addOns: addOns,
        orderAmount: totalPrice,
        specialPersonName: userDetails?.specialPersonName,
        userDetails,
        extraPersons,
        costPerPerson,
        optInForFood,
    }
    const fomatter = new Intl.NumberFormat();

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setUserDetails({
                ...userDetails,
                [name]: value
            })
        } else if (name === 'mobileNumber') {
            const updatedValue = value.replace(/[^0-9.]/g, '')
            if (updatedValue.length <= 10) {
                setUserDetails({
                    ...userDetails,
                    [name]: updatedValue
                })
            }
        } else {
            if (value.length <= 15) {
                setUserDetails({
                    ...userDetails,
                    [name]: value
                })
            }
        }
    }

    const createOrder = async () => {
        setIsSubmitting(true);
        const payloadUpdated = {
            ...orderObject,
            ...payload,
            amount: 750,
            mobileNumber: userDetails.mobileNumber,
            username: userDetails.name
        }
        const request = await fetch(`${API_BASE_URL}/bookings/payment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payloadUpdated)
        });
        const response = await request.json();
        if (response.success) {
            setShowUserInfoModal(true)
            if (response?.data?.data?.instrumentResponse?.redirectInfo?.url) {
                window.location.href = response?.data?.data?.instrumentResponse?.redirectInfo?.url
                localStorage.setItem('orderData', JSON.stringify(orderObject));
            }
            setIsSubmitting(false);
        } else {
            console.error('Invalid URL in the response:', response.data);
            setIsSubmitting(true);
        }
    }

    const onSubmit = () => {
        if (totalPrice) {
            setIsSubmitting(true);
            createOrder();
        }
    }

    useEffect(() => {
        setAmount(totalPrice);
    }, [totalPrice])

    const isDisabled = !userDetails?.name || userDetails?.mobileNumber?.length !== 10 || !userDetails?.specialPersonName || !agreeToConditions;
    const disableCheckout = !Object.keys(slot)?.length > 0 || !slot?.date || !slot?.slot;
    const mobileRegex = /^([0|\\+[0-9]{1,5})?([6-9][0-9]{9})$/;
    const hasError = userDetails?.mobileNumber?.length > 0 && !mobileRegex?.test(userDetails?.mobileNumber);

    return (
        <>
            {isMobile && (
                <div className="info-fixed-container">
                    <div className="grand-total-info sub-heading">
                        <div className="price">
                            <CurrencyRupee /> {fomatter.format(totalPrice)}
                        </div>
                        <div className="view-more" onClick={() => {
                            setShowDetails(!showDetails)
                        }}>
                            {showDetails ? <ExpandMore /> : <ExpandLess />}
                        </div>
                    </div>
                    <div className="checkout-button-container">
                        <Button disabled={disableCheckout} variant='contained' className='app-button' onClick={() => {
                            setShowUserInfoModal(true)
                        }}>
                            Checkout
                        </Button>
                    </div>
                </div>
            )}
            {(!isMobile || showDetails) && (
                <div className="summary-info-container">
                    {Object.keys(theatre)?.length > 0 && (
                        <div className="theatre-name">
                            <Movie /> {theatre?.name || ''}
                        </div>
                    )}
                    {Object.keys(slot)?.length > 0 && (
                        <>
                            <div className="info-section">
                                <CalendarToday />{slot?.date || ''}
                            </div>
                            {slot?.slot && (
                                <div className="info-section">
                                    <Alarm /> {slot?.slot || ''}
                                </div>
                            )}
                        </>
                    )}
                    <div className="content-section">
                        {Object.keys(addOns)?.map((item) => {
                            const isItemPresent = addOns[item].length > 0;
                            return (
                                <>
                                    {isItemPresent ? <SummaryItemCard type={item} data={addOns?.[item]} /> : <></>}
                                </>
                            )
                        })}
                    </div>
                    {extraPersons > 0 && (
                        <div className="extra-persons-info">
                            <div className="item-heading">
                                {extraPersons} Extra persons
                            </div>
                            <div className="price">
                                <CurrencyRupee /> {fomatter.format(extraPersons * costPerPerson)}
                            </div>
                        </div>
                    )}
                    {!isMobile && (
                        <div className="proceed-to-checkout">
                            <div className="grand-total-info sub-heading">
                                <div className="item-heading">
                                    Total
                                </div>
                                <div className="price">
                                    <CurrencyRupee /> {fomatter.format(totalPrice)}
                                </div>
                            </div>
                            <div className="grand-total-info grand-total-info-container">
                                <div className="item-heading">
                                    Grand Total
                                </div>
                                <div className="price">
                                    <CurrencyRupee /> {fomatter.format(totalPrice)}
                                </div>
                            </div>
                            <div className="grand-total-info sub-heading">
                                <div className="item-heading">
                                    Advance Amount
                                </div>
                                <div className="price">
                                    <CurrencyRupee /> 750
                                </div>
                            </div>
                            <Button disabled={disableCheckout} variant='contained' className='app-button' onClick={() => {
                                setShowUserInfoModal(true)
                            }}>
                                Proceed to checkout
                            </Button>
                        </div>
                    )}
                </div>
            )}
            {showModal && (
                <Modal
                    open={showModal}
                    onClose={() => { }}
                    aria-labelledby="Payment Status"
                    aria-describedby=""
                >
                    <Fade in={showModal}>
                        <Box sx={style}>
                            {isPaymentProcessing && (
                                <div className="payment-processing-container">
                                    <div className="image-section">
                                        <img src={loaderImage} alt="Loading" />
                                    </div>
                                    <div className="heading">
                                        Payment is under processing
                                    </div>
                                    <div className="desc">
                                        Your payment is under processing, once completes we will be confirming your order.
                                    </div>
                                </div>
                            )}
                            {isOrderCreating && (
                                <>
                                    <div className="payment-completed-container">
                                        <div className="image-section">
                                            <img src={completedIcon} alt="Completed" />
                                        </div>
                                        <div className="info-section">
                                            <div className="heading">
                                                Payment is Successful
                                            </div>
                                            <div className="desc">
                                                Your payment is Successful, Please wait while we are creating your order.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-creation-container">
                                        <div className="image-section">
                                            <img src={loaderImage} alt="Loading" />
                                        </div>
                                        <div className="heading">
                                            Order creation in progress
                                        </div>
                                        <div className="desc">
                                            We are creating your order, Once completed will redirect you to order confirmation page.
                                        </div>
                                    </div>
                                </>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            )}

            {showUserInfoModal && (
                <Modal
                    open={showUserInfoModal}
                    onClose={() => {
                        setShowUserInfoModal(false);
                        setIsSubmitting(false);
                    }}
                    aria-labelledby="Checkout Information"
                    aria-describedby=""
                >
                    <Fade in={showUserInfoModal}>
                        <Box className="user-info-modal-container" sx={{
                            ...style,
                            width: 1080
                        }} >
                            <div className="info-flex-container">
                                <div className="left-side-section">
                                    <div className="main-heading">
                                        Terms & Conditions
                                    </div>
                                    <div className="heading">
                                        Refund policy: If you cancel the slot at least 72 hours prior to booking time advance amount will be fully
                                        refundable
                                    </div>
                                    <ul>
                                        <li>Any kind of fire is not allowed inside the theaters, considering the sensitivity of the Theaters</li>
                                        <li>You need to bring your own OTT accounts to watch the content</li>
                                        <li>If you want to play your own content: Please inform us in advance over WhatsApp( 9392005252), on
                                            the day of booking</li>
                                        <li>Smoking and Consumption of Alcohol is strictly prohibited inside the Theaters</li>
                                        <li>Outside food is strictly not allowed inside the theatre, considering the carpets and recliners inside the
                                            Theaters</li>
                                        <li>We charge full for children above or equal to 5 years and half for those who are below 5 years</li>
                                        <li>Pets are not allowed inside the premises of the party lounge</li>
                                        <li>Right to admission is reserved by the Management</li>
                                        <li>No refund is possible in case of non-appearance at the booking date</li>
                                        <li>If you decide to shorten your booking for the confirmed slot, in case if you leave early or arrive late,
                                            no refund for the booking is applicable</li>
                                        <li>If customer damages something in the premises of the lounge he must bear corresponding charges</li>
                                    </ul>
                                </div>
                                <div className="right-side-section">
                                    <div className="payment-info">
                                    Secure your booking now with a Rs.750 advance! Don't worry, we'll deduct it from your total bill. Happy booking!
                                    </div>
                                    <div className="heading-section">
                                        Basic Details
                                    </div>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        name="name"
                                        value={userDetails.name}
                                        onChange={onChange}
                                        autoComplete="off"
                                        className='create-form-input'
                                    />
                                    <TextField
                                        id="mobileNumber"
                                        label="Mobile Number (WhatsApp)"
                                        name="mobileNumber"
                                        value={userDetails.mobileNumber}
                                        onChange={onChange}
                                        autoComplete="off"
                                        className='create-form-input'
                                    />
                                    <TextField
                                        id="specialPerson"
                                        label="Special Person Name"
                                        name="specialPersonName"
                                        value={userDetails.specialPersonName}
                                        onChange={onChange}
                                        autoComplete="off"
                                        className='create-form-input'
                                    />
                                    {hasError && (
                                        <div className="error-message">
                                            Please enter valid mobile number
                                        </div>
                                    )}
                                    <div className='terms-conditions-link'>
                                        <FormControlLabel control={<Checkbox value={agreeToConditions} onChange={(e) => setAgreeToConditions(e.target.checked)} />} label={
                                            <div className="terms-conditions-link-info">
                                                I agree with the <a target="_blank" href="/terms-conditions">Terms & Conditions</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a>
                                            </div>
                                        } />
                                    </div>
                                    <div className="modal-buttons-container">
                                        <LoadingButton
                                            variant="outlined"
                                            className="close-button"
                                            onClick={() => {
                                                setShowUserInfoModal(false);
                                                setIsSubmitting(false);
                                            }}>
                                            Close
                                        </LoadingButton>
                                        <LoadingButton
                                            variant="contained"
                                            className="submit-button"
                                            disabled={isDisabled || hasError}
                                            loading={isSubmitting}
                                            onClick={onSubmit}>
                                            Proceed
                                        </LoadingButton>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            )}
        </>
    )
}

export default Summary;
