import React from 'react';

const RefundPolicy = () => {
    return (
        <div className="static-pages-container">
            <div className="app-container-wrapper">
                <div className="heading">
                    Refund Policy
                </div>
                <div className="content">
                    <ul>
                        <li>
                            For the confirmation of your booking we charge an advance payment of Rs.750/-. It will be fully refunded if you cancel 72 hours before your booking slot.
                        </li>
                    </ul>
                    <table>
                        <tr>
                            <th>
                                Cancellation Time
                            </th>
                            <th>
                                Cancellation Fee
                            </th>
                        </tr>
                        <tr>
                            <td>
                                72 hours or more prior to slot time
                            </td>
                            <td>
                                Free Cancellation (100% refund)
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Less than 72 hours before slot time
                            </td>
                            <td>
                                100% of booking value
                            </td>
                        </tr>
                    </table>
                    <ul>
                        <li>
                            <strong>
                                If you need to cancel your booking, please contact us via WhatsApp (9392005252) as soon as possible so we can arrange your refund. We will process your refund within 7 business days of receiving your cancellation request.
                            </strong>
                        </li>
                        <li>
                            If you have any questions or concerns about our refund policy, feel free to contact us. We are always happy to help!
                        </li>
                        <li>
                            No refund is possible in case of non-appearance at the booked date.
                        </li>
                        <li>
                            If you decide to shorten your booking for the confirmed slot, incase if you leave early or arrive late, no refund for the booking is applicable
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default RefundPolicy;
