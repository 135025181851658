import React from 'react';
import ListItemCard from './list-card';
import birthday from '../../assets/birthday.jpeg'
import anniversary from '../../assets/anniversary.jpeg'
import proposal from '../../assets/proposal.jpeg'
import farewell from '../../assets/farewell.jpeg'
import brideToBe from '../../assets/bride.jpeg'
import reunion from '../../assets/get-together.jpeg'
import babyShower from '../../assets/baby-shower.jpeg'
import kittyParty from '../../assets/kitty-party.jpeg'


const events = [
    {
        name: 'Birthday',
        src: birthday
    },
    {
        name: 'Anniversary',
        src: anniversary,
    },
    {
        name: 'Proposal',
        src: proposal
    },
    {
        name: 'Farewell',
        src: farewell,
    },
    {
        name: 'Bride to be',
        src: brideToBe,
    },
    {
        name: 'Reunion',
        src: reunion,
    },
    {
        name: 'Baby Shower',
        src: babyShower,
    },
    {
        name: 'Kitty Party',
        src: kittyParty,
    },
];

const EventCategories = () => {
    return (
        <div className="event-categories-container">
            <div className="heading">
                We’ll make your next celebration very special!
            </div>
            <div className="list-info-container app-container-wrapper">
                {events?.map((item) => {
                    return (
                        <ListItemCard data={item} />
                    )
                })}
            </div>
        </div>
    )
}

export default EventCategories;
